@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Gogh-Heavy';
  src: url('./fonts/Gogh-Heavy.woff2') format('woff2'),
       url('./fonts/Gogh-Heavy.woff') format('woff'),
       url('./fonts/Gogh-Heavy.ttf') format('truetype');
}


@font-face {
  font-family: 'Gogh';
  src: url('./fonts/Gogh-Regular.woff2') format('woff2'),
       url('./fonts/Gogh-Regular.woff') format('woff'),
       url('./fonts/Gogh-Regular.ttf') format('truetype');
}

*{
margin: 0 ;
padding: 0;
box-sizing: border-box;
}

body {
  background-color: #000;
  /* color: #66FCF1; */
  margin: 0;
  font-family: 'Gogh', sans-serif;
  letter-spacing: 1px;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

.gogh-heavy{
  font-family: 'Gogh-Heavy', sans-serif;
}

/* .non-filter{
  filter: brightness(100%) !important;
} */
::selection {
  background: #5E3FDB;
  /* Цвет фона */
  color: #000;
  /* Цвет текста */
}

.ant-spin-text {
  color: #007F43 !important;
  font-weight: 500 !important;
}

.ant-spin-dot-item {
  color: #007F43 !important;
  background-color: #007F43 !important;
}


.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.5s;
  z-index: 100;
}

.sticky-header.scrolled {
  top: -100px; /* Установите здесь высоту вашего хедера */
}

.sticky-header.scrolledToBottom {
  top: 0px; /* Установите здесь высоту вашего хедера */
}


.ant-input{
  color: #fff !important;
}

::placeholder {
  color: #fff !important;
}












/* Underline styles */
.underlining {
  position: relative;
  padding: 0.2em 0;
}

/* Fade in */
.underlining::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width:100%;
  height: 0.1em;
  background-color: #B480FD;
  opacity: 0;
  transition: opacity 900ms, transform 900ms;
  opacity: 1;
  transform: translate3d(-101%, 0, 0);
}

.underlining:hover::after,
.underlining:focus::after {
  transform: translate3d(0, 0, 0);
}


.img-gradient {
  display: block;
  width: 200px;
  height: 200px;
  background-image: linear-gradient(to bottom, black 0%, transparent 100%), url(http://i.imgur.com/Tpn26TW.jpg);
  background-size: cover;
  background-position: center;
}





