/* .containerOfSquares {
  border: 5px solid white;
    position: relative;
    overflow: hidden !important;
    width: 100vw !important;
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
  } */
  
  .square2 {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    opacity: 0;
    border: 3px solid #B480FD;
    animation-duration: 20s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  

  
 
  
  @keyframes scaleAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  